import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import Kontakt from "../components/Kontakt"
import Seo from "../components/Seo"
import SideMenu from "../components/SideMenu"

// Query for Top image and Contact Form background image
export const query = graphql`
  {
    form: file(
      name: { eq: "kontakt" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    top: file(
      name: { eq: "kontakt" }
      sourceInstanceName: { eq: "topImages" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`

const Fotowoltaika = () => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  return (
    <div id="kontakt_top" className="page kontaktPage">
      <Seo title="Kontakt" />
      <Top image={imgTop} />
      <Kontakt />
      <SideMenu base="/kontakt#kontakt_top" />
      <ContactForm backgroundImage={imgForm} />
      <Footer />
    </div>
  )
}

export default Fotowoltaika
