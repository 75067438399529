import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../assets/css/top-styles.css"

const Kontakt = () => {
  return (
    <div className="kontaktElement">
      <StaticImage
        src="../assets/images/kontakt/kontakt_pasek.jpg"
        alt="pasek"
        placeholder="tracedSVG"
        className="squareOne"
        layout="fullWidth"
      />
      <StaticImage
        src="../assets/images/kontakt/kontakt_pasek.jpg"
        alt="pasek"
        placeholder="tracedSVG"
        className="squareTwo"
        layout="fullWidth"
      />
      <div className="kontaktInfoContainer">
        <div className="kontaktInfoElement">
          <div className="blackBorderLeft">
            <h3>GŁÓWNA SIEDZIBA FIRMY</h3>
            <p>
              Przedsiębiosrtwo Wielobranżowe “EKa” Sp. z o.o. <br />
              ul. Świętego Jana 13 <br />
              43-267 Suszec <br />
              tel. (32) 448 82 01 fax. (32) 448 81 97 <br />
              e-mail: eka@eka.pl <br />
              NIP: 648 11 41 414 REGON: 273086903
            </p>
          </div>
          <div className="blackBorderLeft">
            <h3>SKLEP TECHNICZNY EKa</h3>
            <p>
              ul. Świętego Jana 13
              <br />
              43-267 Suszec <br />
              tel. (32) 212 41 91 / 781 999 804 <br />
              e-mail: ekasklep@gmail.com
            </p>
          </div>
          <div className="blackBorderLeft">
            <h3>SKLEP TECHNICZNY TCT</h3>
            <p>
              ul. Pszczyńska 74 <br />
              43-267 Suszec <br />
              tel. 733 670 370
              <br />
              e-mail: tctsklep@gmail.com
            </p>
          </div>
        </div>
        <div className="kontaktInfoElement">
          <div className="blackBorderLeft">
            <h3>EKOLOGICZNA ENERGIA</h3>
            <p>
              ul. Pszczyńska 74 <br />
              43-267 Suszec <br />
              tel. 781 144 999 <br />
              e-mail: oze@eka.pl
            </p>
          </div>
          <div className="blackBorderLeft">
            <h3>PRODUKCJA</h3>
            <p>
              ul. Świętego Jana 13 <br />
              43-267 Suszec <br />
              tel/fax (32) 328 92 12
            </p>
          </div>
          <div className="blackBorderLeft">
            <h3>SKLEP MIĘSNO - WĘDLINIARSKI</h3>
            <p>
              ul. Marii Curie Skłodowskiej 65a <br />
              41-949 Piekary Śląskie <br />
              tel/fax (32) 287 91 65
            </p>
          </div>
          <div className="blackBorderLeft">
            <h3>SKLEP SPOŻYWCZY</h3>
            <p>
              ul. Bytomska 169 <br />
              41-940 Piekary Śląskie <br />
              tel/fax (32) 392 19 70
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Kontakt
